<template>
  <!-- 车辆信息提示框 -->
  <div class="container" v-if="objectInfo">
    <div class="row vehicle-object-info">
      <span>所在位置：{{ mapAddress }}</span>
      <span><a-button @click="setMapAddress">获取位置信息</a-button></span>
    </div>
    <div class="row">
      <div class="column">
        <span style="min-width: 60px">车牌号：</span>
        <span>
          <span id="licensePlateNum" v-if="source === 'map'"></span>
          <span v-else> {{ objectInfo.licensePlateNum }}</span>
          <a-tooltip placement="rightTop">
            <template slot="title">
              <span>自动驾驶车辆</span>
            </template>
            <a-icon :component="vehicleAutoIcon" :style="{ fontSize: '1.5em' }" />
          </a-tooltip>
        </span>
      </div>
      <div class="column">
        <span>车辆自编号：</span>
        <span id="vehicleId" v-if="source === 'map'" />
        <span v-else> {{ objectInfo.vehicleId }}</span>
      </div>
    </div>
    <div class="row">
      <div class="column">
        <span>车辆名称：</span>
        <span id="areaAndName" v-if="source === 'map'" />
        <span v-else>{{ objectInfo.name }} </span>
      </div>
      <div class="column">
        <span>在线状态：</span>
        <span id="online" v-if="source === 'map'" />
        <span v-else> {{ objectInfo.online ? '在线' : '离线' }}</span>
      </div>
    </div>
    <div class="row">
      最近一次上报：<span id="time" v-if="source === 'map'" />
      <span v-else> {{ objectInfo.time }}</span>
    </div>
    <div class="row">
      <div class="column">
        <span>速度：</span>
        <span id="speed" v-if="source === 'map'" />
        <span v-else>
          {{ objectInfo.runningState != null ? formatSpeedEx(Number(objectInfo.runningState.speed)).toFixed(2) : 0 }}KM/h
        </span>
      </div>
      <div class="column">
        <span>方向：</span>
        <span class="direction">
          <span id="direction" v-if="source === 'map'" />
          <span v-else>{{ locationHeading }} </span> °
          <img id="imgMap" :src="arrowUrl" v-if="source === 'map'" />
          <img v-else id="imgInfo" :src="arrowUrl" />
        </span>
      </div>
    </div>
    <div class="row">
      <div class="column">
        <span>作业状态：</span>
        <span id="workStatus" v-if="source === 'map'" />
        <span v-else> {{ workStatus }} </span>
      </div>
      <div class="column">
        <span>电量：</span>
        <span id="soc" v-if="source === 'map'" />
        <span v-else> {{ runningStateSoc }}</span>
      </div>
    </div>
    <div class="row">
      <div class="column">
        <span>今日总里程：</span>
        <span id="odo" v-if="source === 'map'" />
        <span v-else>
          {{
            objectInfo.ctrlModeData != null && objectInfo.ctrlModeData.odo != -1
              ? formatMilesEx(objectInfo.ctrlModeData.odo)
              : '-'
          }}
        </span>
      </div>
      <div class="column">
        <span>今日总时长：</span>
        <span id="ctlTime" v-if="source === 'map'" />
        <span v-else>
          {{
            objectInfo.ctrlModeData != null && objectInfo.ctrlModeData.ctlTime != -1
              ? formatTimeEx(objectInfo.ctrlModeData.ctlTime)
              : '-'
          }}</span
        >
      </div>
    </div>
    <div class="row">
      <div class="column">
        <span>智驾里程：</span>
        <span id="auto_ctl_odom_sum" v-if="source === 'map'" />
        <span v-else>
          {{
            objectInfo.ctrlModeData != null && objectInfo.ctrlModeData.auto_ctl_odom_sum != -1
              ? formatMilesEx(objectInfo.ctrlModeData.auto_ctl_odom_sum)
              : '-'
          }}</span
        >
      </div>
      <div class="column">
        <span>遥控里程：</span>
        <span id="remote_ctl_odom_sum" v-if="source === 'map'" />
        <span v-else>
          {{
            objectInfo.ctrlModeData != null && objectInfo.ctrlModeData.remote_ctl_odom_sum != -1
              ? formatMilesEx(objectInfo.ctrlModeData.remote_ctl_odom_sum)
              : '-'
          }}</span
        >
      </div>
    </div>
    <div class="row">
      <div class="column">
        <span>智驾时长：</span>
        <span id="auto_ctl_time_sum" v-if="source === 'map'" />
        <span v-else>
          {{
            objectInfo.ctrlModeData != null && objectInfo.ctrlModeData.auto_ctl_time_sum != -1
              ? formatTimeEx(objectInfo.ctrlModeData.auto_ctl_time_sum)
              : '-'
          }}</span
        >
      </div>
      <div class="column">
        <span>遥控时长：</span>
        <span id="remote_ctl_time_sum" v-if="source === 'map'" />
        <span v-else>
          {{
            objectInfo.ctrlModeData != null && objectInfo.ctrlModeData.remote_ctl_time_sum != -1
              ? formatTimeEx(objectInfo.ctrlModeData.remote_ctl_time_sum)
              : '-'
          }}</span
        >
      </div>
    </div>
    <div class="row">
      <div class="column">
        <span>mapId：</span>
        <span id="mapId" v-if="source === 'map'" />
        <span v-else>
          {{
            objectInfo.businessState != null && objectInfo.businessState.map_id != -1
              ? objectInfo.businessState.map_id
              : '-'
          }}</span
        >
      </div>
      <div class="column">
        <span>mapVersion：</span>
        <span id="mapVersion" v-if="source === 'map'" />
        <span v-else>
          {{
            objectInfo.businessState != null && objectInfo.businessState.map_version != -1
              ? objectInfo.businessState.map_version
              : '-'
          }}</span
        >
      </div>
    </div>
  </div>
</template>
<script>
import get from 'lodash/get'
import allIcons from '@/core/icons'
import MonitorBase from '@/views/monitor/monitorBase'
import { formatTime, formatMiles, formatSpeed } from '@/utils/formatHelper'
export default {
  name: 'MapVehicleInfoWindow',
  mixins: [MonitorBase],
  props: {
    objectInfo: {
      type: Object,
      default: () => {}
    },
    taskStatusOptions: {
      type: Array,
      default: () => {}
    },
    mapObj: {
      type: Object,
      default: () => {}
    },
    source: {
      type: String,
      default: ''
    }
  },
  watch: {
    'objectInfo.vehicleId'(newVal, oldVal) {
      this.mapAddress = ''
    }
  },
  mounted() {
    console.log('infoWindow', this.isMapInfoWindow)
  },
  data() {
    return {
      formatSpeedEx: formatSpeed,
      vehicleAutoIcon: allIcons.vehicleAutoIcon,
      mapAddress: '',
      lng: 0,
      lat: 0
    }
  },
  computed: {
    locationHeading() {
      const headingVal = Number(get(this.objectInfo, 'location.heading', 0))
      this.$nextTick(() => {
        const el = document.getElementById('imgInfo')
        this.setArrow(el, headingVal)
      })
      return headingVal ? headingVal.toFixed(2) : headingVal
    },
    runningStateSoc() {
      const soc = Number(get(this.objectInfo, 'runningState.soc', 0))
      return soc ? soc.toFixed(2) : soc
    },
    workStatus() {
      // console.log('getWorkStatus')
      if (!this.taskStatusOptions) {
        return ''
      }
      const workStatus = this.objectInfo.businessState ? this.objectInfo.businessState.state : -1
      return this.selectDictLabel(this.taskStatusOptions, workStatus)
    }
  },
  methods: {
    formatTimeEx(t) {
      return formatTime(t)
    },
    formatMilesEx(m) {
      return formatMiles(m)
    },
    async getData() {
      if (!this.taskStatusOptions) {
        await this.getDicts('iot_task_state').then((response) => {
          this.taskStatusOptions = response.data
        })
      }
    },
    // 地图弹窗需要手动设置弹窗信息
    async setFormData(form) {
      // console.log('setFormData')
      if (!form) {
        return
      }
      await this.getData()
      this.mapAddress = ''
      this.lng = form.lng
      this.lat = form.lat
      this.setSpanValue('time', form.time)
      this.setSpanValue('licensePlateNum', form.licensePlateNum)
      this.setSpanValue('vehicleId', form.vehicleId)
      this.setSpanValue('areaAndName', form.name)
      this.setSpanValue('online', form.online ? '在线' : '离线')
      // 速度
      const speed = form.runningState != null ? formatSpeed(Number(form.runningState.speed)).toFixed(2) : 0
      this.setSpanValue('speed', speed + 'KM/H')
      // 方向
      const headingVal = Number(get(form, 'location.heading', 0))
      const el = document.getElementById('imgMap')
      this.setArrow(el, headingVal)
      this.setSpanValue('direction', headingVal ? headingVal.toFixed(2) : headingVal)
      // 作业状态
      const workStatus = form.businessState ? form.businessState.state : -1
      this.setSpanValue('workStatus', this.selectDictLabel(this.taskStatusOptions, workStatus))
      // 电量
      const soc = Number(get(form, 'runningState.soc', 0))
      this.setSpanValue('soc', soc ? soc.toFixed(2) : soc)
      this.setSpanValue(
        'odo',
        form.ctrlModeData != null && form.ctrlModeData.odo !== -1 ? formatMiles(form.ctrlModeData.odo) : '-'
      )
      this.setSpanValue(
        'ctlTime',
        form.ctrlModeData != null && form.ctrlModeData.ctlTime !== -1 ? formatTime(form.ctrlModeData.ctlTime) : '-'
      )
      this.setSpanValue(
        'auto_ctl_odom_sum',
        form.ctrlModeData != null && form.ctrlModeData.auto_ctl_odom_sum !== -1
          ? formatMiles(form.ctrlModeData.auto_ctl_odom_sum)
          : '-'
      )
      this.setSpanValue(
        'remote_ctl_odom_sum',
        form.ctrlModeData != null && form.ctrlModeData.remote_ctl_odom_sum !== -1
          ? formatMiles(form.ctrlModeData.remote_ctl_odom_sum)
          : '-'
      )
      this.setSpanValue(
        'auto_ctl_time_sum',
        form.ctrlModeData != null && form.ctrlModeData.auto_ctl_time_sum !== -1
          ? formatTime(form.ctrlModeData.auto_ctl_time_sum)
          : '-'
      )
      this.setSpanValue(
        'remote_ctl_time_sum',
        form.ctrlModeData != null && form.ctrlModeData.remote_ctl_time_sum !== -1
          ? formatTime(form.ctrlModeData.remote_ctl_time_sum)
          : '-'
      )

      const mapId = form.businessState ? form.businessState.map_id : '-'
      // eslint-disable-next-line no-unneeded-ternary
      this.setSpanValue('mapId', mapId ? mapId : '-')
      const mapVersion = form.businessState ? form.businessState.map_version : '-'
      // eslint-disable-next-line no-unneeded-ternary
      this.setSpanValue('mapVersion', mapVersion ? mapVersion : '-')
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  padding-right: 5px;
  .row {
    display: flex;
    .column {
      flex: 1;
      display: flex;
      align-items: center;
      .direction {
        display: flex;
        align-items: center;
      }
      img {
        width: 25px;
        height: 25px;
        min-width: 25px;
      }
      span:last-child {
        word-break: break-all;
        padding-right: 5px;
        line-height: 1.1;
      }
    }
  }
}
</style>
<style lang="less">
.vehicle-object-info {
  justify-content: space-between;
  // height: 40px;
  span:first-child {
    padding-right: 3px;
  }
  .ant-btn {
    height: 22px !important;
    border-radius: 1px !important;
    padding-left: 5px;
    padding-right: 5px;
  }
}
</style>
