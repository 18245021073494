var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.objectInfo
    ? _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row vehicle-object-info" }, [
          _c("span", [_vm._v("所在位置：" + _vm._s(_vm.mapAddress))]),
          _c(
            "span",
            [
              _c("a-button", { on: { click: _vm.setMapAddress } }, [
                _vm._v("获取位置信息"),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "column" }, [
            _c("span", { staticStyle: { "min-width": "60px" } }, [
              _vm._v("车牌号："),
            ]),
            _c(
              "span",
              [
                _vm.source === "map"
                  ? _c("span", { attrs: { id: "licensePlateNum" } })
                  : _c("span", [
                      _vm._v(" " + _vm._s(_vm.objectInfo.licensePlateNum)),
                    ]),
                _c(
                  "a-tooltip",
                  { attrs: { placement: "rightTop" } },
                  [
                    _c("template", { slot: "title" }, [
                      _c("span", [_vm._v("自动驾驶车辆")]),
                    ]),
                    _c("a-icon", {
                      style: { fontSize: "1.5em" },
                      attrs: { component: _vm.vehicleAutoIcon },
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "column" }, [
            _c("span", [_vm._v("车辆自编号：")]),
            _vm.source === "map"
              ? _c("span", { attrs: { id: "vehicleId" } })
              : _c("span", [_vm._v(" " + _vm._s(_vm.objectInfo.vehicleId))]),
          ]),
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "column" }, [
            _c("span", [_vm._v("车辆名称：")]),
            _vm.source === "map"
              ? _c("span", { attrs: { id: "areaAndName" } })
              : _c("span", [_vm._v(_vm._s(_vm.objectInfo.name) + " ")]),
          ]),
          _c("div", { staticClass: "column" }, [
            _c("span", [_vm._v("在线状态：")]),
            _vm.source === "map"
              ? _c("span", { attrs: { id: "online" } })
              : _c("span", [
                  _vm._v(" " + _vm._s(_vm.objectInfo.online ? "在线" : "离线")),
                ]),
          ]),
        ]),
        _c("div", { staticClass: "row" }, [
          _vm._v(" 最近一次上报："),
          _vm.source === "map"
            ? _c("span", { attrs: { id: "time" } })
            : _c("span", [_vm._v(" " + _vm._s(_vm.objectInfo.time))]),
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "column" }, [
            _c("span", [_vm._v("速度：")]),
            _vm.source === "map"
              ? _c("span", { attrs: { id: "speed" } })
              : _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.objectInfo.runningState != null
                          ? _vm
                              .formatSpeedEx(
                                Number(_vm.objectInfo.runningState.speed)
                              )
                              .toFixed(2)
                          : 0
                      ) +
                      "KM/h "
                  ),
                ]),
          ]),
          _c("div", { staticClass: "column" }, [
            _c("span", [_vm._v("方向：")]),
            _c("span", { staticClass: "direction" }, [
              _vm.source === "map"
                ? _c("span", { attrs: { id: "direction" } })
                : _c("span", [_vm._v(_vm._s(_vm.locationHeading) + " ")]),
              _vm._v(" ° "),
              _vm.source === "map"
                ? _c("img", { attrs: { id: "imgMap", src: _vm.arrowUrl } })
                : _c("img", { attrs: { id: "imgInfo", src: _vm.arrowUrl } }),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "column" }, [
            _c("span", [_vm._v("作业状态：")]),
            _vm.source === "map"
              ? _c("span", { attrs: { id: "workStatus" } })
              : _c("span", [_vm._v(" " + _vm._s(_vm.workStatus) + " ")]),
          ]),
          _c("div", { staticClass: "column" }, [
            _c("span", [_vm._v("电量：")]),
            _vm.source === "map"
              ? _c("span", { attrs: { id: "soc" } })
              : _c("span", [_vm._v(" " + _vm._s(_vm.runningStateSoc))]),
          ]),
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "column" }, [
            _c("span", [_vm._v("今日总里程：")]),
            _vm.source === "map"
              ? _c("span", { attrs: { id: "odo" } })
              : _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.objectInfo.ctrlModeData != null &&
                          _vm.objectInfo.ctrlModeData.odo != -1
                          ? _vm.formatMilesEx(_vm.objectInfo.ctrlModeData.odo)
                          : "-"
                      ) +
                      " "
                  ),
                ]),
          ]),
          _c("div", { staticClass: "column" }, [
            _c("span", [_vm._v("今日总时长：")]),
            _vm.source === "map"
              ? _c("span", { attrs: { id: "ctlTime" } })
              : _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.objectInfo.ctrlModeData != null &&
                          _vm.objectInfo.ctrlModeData.ctlTime != -1
                          ? _vm.formatTimeEx(
                              _vm.objectInfo.ctrlModeData.ctlTime
                            )
                          : "-"
                      )
                  ),
                ]),
          ]),
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "column" }, [
            _c("span", [_vm._v("智驾里程：")]),
            _vm.source === "map"
              ? _c("span", { attrs: { id: "auto_ctl_odom_sum" } })
              : _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.objectInfo.ctrlModeData != null &&
                          _vm.objectInfo.ctrlModeData.auto_ctl_odom_sum != -1
                          ? _vm.formatMilesEx(
                              _vm.objectInfo.ctrlModeData.auto_ctl_odom_sum
                            )
                          : "-"
                      )
                  ),
                ]),
          ]),
          _c("div", { staticClass: "column" }, [
            _c("span", [_vm._v("遥控里程：")]),
            _vm.source === "map"
              ? _c("span", { attrs: { id: "remote_ctl_odom_sum" } })
              : _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.objectInfo.ctrlModeData != null &&
                          _vm.objectInfo.ctrlModeData.remote_ctl_odom_sum != -1
                          ? _vm.formatMilesEx(
                              _vm.objectInfo.ctrlModeData.remote_ctl_odom_sum
                            )
                          : "-"
                      )
                  ),
                ]),
          ]),
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "column" }, [
            _c("span", [_vm._v("智驾时长：")]),
            _vm.source === "map"
              ? _c("span", { attrs: { id: "auto_ctl_time_sum" } })
              : _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.objectInfo.ctrlModeData != null &&
                          _vm.objectInfo.ctrlModeData.auto_ctl_time_sum != -1
                          ? _vm.formatTimeEx(
                              _vm.objectInfo.ctrlModeData.auto_ctl_time_sum
                            )
                          : "-"
                      )
                  ),
                ]),
          ]),
          _c("div", { staticClass: "column" }, [
            _c("span", [_vm._v("遥控时长：")]),
            _vm.source === "map"
              ? _c("span", { attrs: { id: "remote_ctl_time_sum" } })
              : _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.objectInfo.ctrlModeData != null &&
                          _vm.objectInfo.ctrlModeData.remote_ctl_time_sum != -1
                          ? _vm.formatTimeEx(
                              _vm.objectInfo.ctrlModeData.remote_ctl_time_sum
                            )
                          : "-"
                      )
                  ),
                ]),
          ]),
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "column" }, [
            _c("span", [_vm._v("mapId：")]),
            _vm.source === "map"
              ? _c("span", { attrs: { id: "mapId" } })
              : _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.objectInfo.businessState != null &&
                          _vm.objectInfo.businessState.map_id != -1
                          ? _vm.objectInfo.businessState.map_id
                          : "-"
                      )
                  ),
                ]),
          ]),
          _c("div", { staticClass: "column" }, [
            _c("span", [_vm._v("mapVersion：")]),
            _vm.source === "map"
              ? _c("span", { attrs: { id: "mapVersion" } })
              : _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.objectInfo.businessState != null &&
                          _vm.objectInfo.businessState.map_version != -1
                          ? _vm.objectInfo.businessState.map_version
                          : "-"
                      )
                  ),
                ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }